var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"BreedingView"}},[_c('div',{staticClass:"main"},[_c('Collection'),_vm._m(0),_vm._m(1)],1),_c('SelectBreed',{attrs:{"token1":this.firstToken,"token2":this.secondToken},on:{"clear":function () {
        this$1.firstToken = null;
        this$1.secondToken = null;
      }}}),_c('PotionLibrary',{attrs:{"token":_vm.potionToken}}),_c('EggCollection',{on:{"selectPotion":_vm.setTokenPotion,"clearPotion":_vm.clearPotion}}),_c('RyuCollection',{attrs:{"disabled":[
      _vm.firstToken ? _vm.firstToken.tokenId : -1,
      _vm.secondToken ? _vm.secondToken.tokenId : -1 ]},on:{"selectToken":_vm.setToken}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title_container"},[_c('img',{attrs:{"src":require("@/assets/avax_logo.webp"),"alt":"Avax Logo","id":"avax_logo"}}),_c('span',{attrs:{"id":"title"}},[_vm._v("Breeding")]),_c('span',{staticClass:"sub_title"},[_vm._v(" Breeding requires two Gen1 Dragons. Breeding cost 2000 $nRYU. The egg will incubate for 33 days before revealing your Gen2 dragon. Potions can be purchased to affect the outcome off the Gen2 dragon. There are 4 potions that can affect breeding and incubation. Potions consist of Speed Ups, Strip Down, Fraternal Twins, and Identical Twins. ")]),_c('div',{attrs:{"id":"line"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"two_sides"},[_c('div',{staticClass:"side"},[_c('span',{staticClass:"title_side"},[_vm._v(" What's next ? ")]),_c('span',{staticClass:"text_side"},[_vm._v(" Select two dragons and add a potion if you dare. Each dragon has a limited number of stamina points, so use them wisely. Incubation lasts 33 days, the eggs are transferable during this period. Eggs can be affected by a maximum of two Speed Up potions during the incubation period. ")])]),_c('span',{staticClass:"vertical_line"}),_c('div',{staticClass:"side"},[_c('span',{staticClass:"title_side"},[_vm._v(" Disclaimer ")]),_c('span',{staticClass:"text_side"},[_vm._v(" Breeding Potions are nontransferable. They are wallet bound pay-for activities. Potions are limited in the breeding game and may run out. Once the supply of potions runs out for the breeding game, they may not be restocked. ")])])])}]

export { render, staticRenderFns }